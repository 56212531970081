.drawer {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 9999;

	display: none;
	width: 100%;
	height: 100%;

	.drawer-background {
		position: fixed;
		top: 0;
		left: 0;

		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.5);
	}

	.drawer-foreground {
		position: fixed;

		width: 300px;
		height: 100%;
		overflow: auto;
		background-color: #fff;
	}
}
